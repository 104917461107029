import React from 'react';
import Image from 'gatsby-image';
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {Link} from 'gatsby';
import Fade from 'react-reveal/Fade';
const Boton = styled(Link) ` 
    margin-top: 2rem;
    padding: 1rem;
    background-color: #18184f;
    width: 100%;
    color: #FFF;
    display: block;
    text-decoration: none;
    text-transform:uppercase;
    font-weight:700;
    text-align:center;

`;

const PricingPreview = ({pricing}) => {
  
    const {descripcion,imagen,titular,articulo,precio,slug} = pricing;
    const link = slug+"/"+articulo;
    return (
      <div
        css={css`
         
          margin-bottom: 2rem;
        `}
      >
        <div
        css={css`
          text-align:center;
        `}
      >
        
        </div>
        <div
          css={css`
            padding: 3rem;
          `}
        >
          { slug === "mantenimiento" ? 
          <div css={css`height: 3rem;`}>
            <h3 css={css`
            text-align: center;
            font-size:  1.5rem;
            `}>{titular} </h3>
          </div>
          
          :
            <Fade><h3 css={css`
            text-align: center;
            font-size:  3rem;
            `}>{titular} 
            </h3></Fade>
            }


          <div css={css`height: 100%;`}>
          <div css={css`font-size: 2rem;`} 
          dangerouslySetInnerHTML={{ __html: descripcion }}/>
          {slug==="desarrolloweb" ?<p css={css`font-size: 2rem;`}> {precio}</p> :<p css={css`font-size: 2rem;`}> ${precio}</p> }
         
         <a href={link}>
         <Image fluid={imagen.fluid} />
         </a>
        <Fade>  <Boton to={articulo}>Mas Detalles</Boton></Fade>
          </div>
        </div>
      </div>
    )
}
 
export default PricingPreview;