import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import PricingPreview from '../components/pricingPreview';
import HeadShake from 'react-reveal/HeadShake';
import Flip from 'react-reveal/Flip';
import Monarca from '../video/video.mp4';
import Fade from 'react-reveal/Fade';
import HeaderColor from "./headerColor"

export const query = graphql` 
query ($slug: String! ) {
  servicio: allDatoCmsServicio(filter: {slug: {eq: $slug}}) {
    nodes {
      titulo
      slug
      descripcioncorta
      portada {
        fluid(maxWidth:1200){
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
  precio: allDatoCmsPricing(filter: {slug: {eq: $slug}}) {
    nodes {
      titular
      id
      slug
      descripcion
      precio
      articulo
      imagen {
        fluid(maxWidth:1200){
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
}

   
`
const ListadoPrecios = styled.ul`
  
max-width:1200px;
width: 95%;
margin: 4rem auto 0 auto;
@media (min-width: 768px){
    display:grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 2rem;
}
`


const ListadoInformacion = styled.ul`
    max-width:1200px;
    width: 100%; 
    margin: 4rem auto 0 auto;
    @media (min-width: 768px){
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3rem;
        
    }
`



const ServicioTemplate = ({data}) => {
 
  const {titulo} = data.servicio.nodes[0];
  const pricings = data.precio.nodes;

 /*  const {precio,titular,descripcion} = pricings; */
 
    return (
      
      <Layout>
        <HeaderColor/>
        
      {/*   lISTADO DE INFORMACION */}
          {data.servicio.nodes[0].slug === "desarrolloweb" ?  
   
            null
          
          : 
       
          
            
        <div css={css`align-items:center; text-align:center;`}>
          <div>
        <h1 css={css`
            text-align: center;
            margin-top: 4rem;
        
        `}>
          {titulo}</h1>
        
            </div>
           
        </div>
            }
          
         
       
        <ListadoPrecios>
        { pricings.map( pricing =>(
              
              <PricingPreview key={pricing.id} pricing={pricing} />
              
            )       
            )}
            </ListadoPrecios>

            
        
       
      </Layout>
  
    )
}
 
export default ServicioTemplate;